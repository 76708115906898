<template>
  <div class="gradientWrapper">
    <div class="formWrapper">
      <h2>Contact</h2>
      <form name="outfit7neo" method="post" novalidate @submit.prevent="onSubmit">
        <!-- Email  -->
        <div class="email">
          <input v-model="state.email" type="text" name="email" placeholder="E-mail" :class="emailClass" />
          <div class="errorContainer">
            <label v-if="v$.email.$error" class="error">field is required</label>
          </div>
        </div>

        <!-- Message -->
        <div class="message">
          <textarea v-model="state.message" name="message" cols="40" rows="5" maxlength="500" placeholder="Message"
            :class="messageClass"></textarea>
          <div class="errorContainer">
            <label v-if="v$.message.$error" class="error">field is required</label>
          </div>
        </div>

        <div class="privacyConsent">
          <label :class="containerClass" for="privacyConsent">
            <input id="privacyConsent" v-model="state.privacyConsent" name="privacyConsent" type="checkbox"
              aria-describedby="privacyConsent" :class="privacyConsentClass" />
            <span class="checkmark"></span>
            I have read and agree to the
            <nuxt-link tag="a" to="/privacy-web">Privacy Policy</nuxt-link>.
          </label>
        </div>

        <!-- Submit button -->
        <button class="submit-btn" type="submit">Send</button>
      </form>

      <!-- Submit messages -->
      <div v-if="hasSubmitted" class="submitWrapper alert alert-success">
        <p>Thank you for getting in touch. We’ll get back to you soon.</p>
      </div>
      <div v-else-if="hasError" class="submitWrapper alert alert-danger" role="alert">
        <p>
          Ooops... Something went wrong. Please try again or send an email to:
          <a href="mailto:support@outfit7neo.com">support@outfit7neo.com.</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, computed } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, email, maxLength } from '@vuelidate/validators';

// Constants
const RULES = {
  email: {
    required,
    email,
    maxLength: maxLength(45)
  },
  message: {
    required,
    maxLength: maxLength(500)
  },
  privacyConsent: {
    checked: (value: boolean) => value === true
  }
};

// Refs/reactives
const state = reactive<ContactFormInterface>({
  email: '',
  message: '',
  privacyConsent: false
});

const v$ = useVuelidate(RULES, state);
const hasSubmitted = ref(false);
const hasError = ref(false);

// Computed
const emailClass = computed(() => {
  return { error: v$.value.email.$error };
});

const messageClass = computed(() => {
  return { error: v$.value.message.$error };
});

const privacyConsentClass = computed(() => {
  return { inputerror: v$.value.privacyConsent.$error };
});

const containerClass = computed(() => {
  return {
    container: true,
    labelerror: v$.value.privacyConsent.$error
  };
});

// Functions
async function onSubmit($event: Event) {
  $event.preventDefault();

  hasSubmitted.value = false;
  hasError.value = false;

  const isValid = await v$.value.$validate();
  if (!isValid) return;

  const ENDPOINT = 'https://mythiclegends.zendesk.com/api/v2/requests/';
  const data = {
    request: {
      ticket_form_id: 14085529607185, // HDS form ID
      subject: 'Outfit7 Neo - Support',
      comment: {
        body: state.message + '\n\n------------------\nSubmitted from: https://outfit7neo.com'
      },
      requester: {
        name: state.email,
        email: state.email
      },
      tags: ['web_widget']
    }
  };

  await fetch(ENDPOINT, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
    .then(res => {
      if (!res.ok) {
        hasError.value = true;
        return;
      }

      hasSubmitted.value = true;
      state.email = '';
      state.message = '';
      state.privacyConsent = false;
      v$.value.$reset();
    })
    .catch(error => {
      console.error(error);
      hasError.value = true;
    });
}
</script>

<style scoped lang="scss">
@import '~/assets/design';

// override blue input outlines
input:focus,
button,
textarea:focus {
  outline: none;
}

.gradientWrapper {
  opacity: 1;

  @media screen and (min-width: 992px) {
    background: unset;
  }

  .formWrapper {
    padding: 0;
    position: relative;
    display: block;
    margin: 50px 0 0 0;

    form {
      width: 100%;
    }

    h2 {
      font-family: 'O7TF Display';
      font-weight: 600;
      margin: 80px 0 32px;
      line-height: 49px;
      color: #fff;
      text-align: center;
      font-size: 36px;
    }

    .email input,
    .message textarea {
      display: block;
      width: 100%;
      padding: 0.5rem 1rem;
      font-size: 1.2rem;
      line-height: 1.5;
      border-radius: 0.3rem;
      background-color: #333333;
      background-clip: padding-box;
      border: 1px solid #333333;
      font-weight: 300;
      color: #808080;

      &.error {
        border: 1px solid $color-stroke-red;
        animation-name: shakeError;
        animation-duration: 0.6s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forward;
      }
    }

    label {
      color: $color-stroke-red;

      &.error {
        animation-name: shakeError;
        animation-duration: 0.6s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forward;
      }
    }

    button {
      background: $color-stroke-red;
      border: none;
      color: #ffffff;
      padding: 8px 20px;
      border-radius: 4px;
      margin-bottom: 53px;
      font-family: 'O7TF Display';
      font-weight: 600;

      p {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 22px;
      }
    }

    .submitWrapper {
      margin-bottom: 2rem;
      max-width: 500px;

      p {
        margin-bottom: 0px;
      }

      &.alert {
        padding: 0.5rem 1rem;
        border-radius: 0.3rem;

        p {
          margin: 0;
        }
      }

      &.alert-success {
        background-color: $color-alert-success-bg;
        color: $color-alert-success-text;
      }

      &.alert-danger {
        background-color: $color-alert-danger-bg;
        color: $color-alert-danger-text;

        a {
          color: $color-stroke-red;
        }
      }
    }
  }
}

.noWrapper {
  background: unset;
}

// custom checkbox for privacy
.privacyConsent {
  display: flex;
  margin-top: 0px;
  margin-bottom: 32px;

  input[type='checkbox'] {
    // border: 1px solid #ced4da;
    width: 80px;
    height: 10px;
    margin-right: 10px;
  }

  label.container {
    color: #cccccc;
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 14px;
    line-height: 19px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    // On mouse-over, add a grey background color
    &:hover input~.checkmark {
      background-color: #333333;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked~.checkmark:after {
        display: block;
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #333333;
      border: 1px solid #333333;
      border-radius: 0.3rem;

      /* Create the checkmark/indicator (hidden when not checked) */
      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 5px;
        top: 3px;
        width: 8px;
        height: 10px;
        border: solid $color-stroke-red;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    &.labelerror {
      color: $color-stroke-red;
      animation-name: shakeError;
      animation-duration: 0.6s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forward;
    }

    a {
      color: $color-stroke-red;
    }
  }
}

.errorContainer {
  height: 32px;
}

.submit-btn {
  line-height: 22px;
  font-size: 16px;
}

// input error animation
@keyframes shakeError {
  0% {
    transform: translateX(0);
  }

  15% {
    transform: translateX(0.375rem);
  }

  30% {
    transform: translateX(-0.375rem);
  }

  45% {
    transform: translateX(0.375rem);
  }

  60% {
    transform: translateX(-0.375rem);
  }

  75% {
    transform: translateX(0.375rem);
  }

  90% {
    transform: translateX(-0.375rem);
  }

  100% {
    transform: translateX(0);
  }
}
</style>
